footer.footer {
  background: #011642;
  width: 100%;
  color: #fff;

  .wrapper {
    max-width: 1200px;
    width: calc(100% - 2rem);
    margin: 0 auto;
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo img {
      position: relative;
      width: 212px;
      height: 25.27px;
    }

    .offices {
      display: flex;
      gap: 4rem;
      align-items: center;
      justify-content: space-between;

      .office {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: .5rem;

        h4 {
          margin: 0;
          font-family: "futura-bold";
        }

        p {
          margin: 0;
          font-size: 14px;
        }
      }
    }

    .social {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      a {
        position: relative;
        height: 1.5rem;
        width: 1.5rem;
        transition: all .2s;

        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  footer.footer .wrapper {
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
    margin-left: 2rem;

    .offices {
      flex-direction: column;
      gap: 2rem;
      align-items: flex-start;
    }

    .social {
      justify-content: flex-start;
    }
  }
}