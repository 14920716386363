.team__heading {
  display: flex;
  flex-direction: column;
  margin: 4rem auto;
  max-width: 1200px;
  width: calc(100% - 2rem);
  text-align: justify;
  font-size: 18px;
}

.team__section {
  display: flex;
  flex-direction: column;
  margin: 4rem auto;
  max-width: 1200px;
  width: calc(100% - 2rem);
  gap: 1rem;

  h3 {
    font-size: 26px;
    color: #013974;
    margin-bottom: -.8rem;
  }

  span.line {
    width: 10%;
    margin-top: 1rem;
    height: 3px;
    background-color: #ccad61;
  }

  .cards {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;

    &._4inline {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .deck {
      position: relative;
      -webkit-perspective: 1000px;
      perspective: 1000px;
      width: calc(100%);
      height: 620px;

      &.small {
        height: 200px;
      }
    }

    .card-alt {
      display: flex;
      flex-direction: column;

      .face {
        background-color: whitesmoke;
        border-radius: 4px;

        img {
          width: 100%;
          object-fit: cover;
        }

        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        h4 {
          font-size: 22px;
          color: #013974;
          text-align: center;
          margin: 1rem;

          &.alt {
            margin: 1rem 1rem 1.5rem;
          }
        }

        span.line {
          height: 3px;
          width: 4rem;
          background: #ccad61;
          margin: 1rem auto;
        }

        &.mar-all {
          padding: .5rem 1rem;
          
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .card {
      width: 100%;
      height: 100%;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-transition: all .5s linear;
      transition: all .5s linear;
      border-radius: 4px;

      .face {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        background-color: whitesmoke;
        border-radius: 4px;

        img {
          width: 100%;
          object-fit: cover;
        }

        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 40%;
        }

        h4 {
          font-size: 22px;
          color: #013974;
          text-align: center;
          margin: 1rem;
        }

        span.line {
          height: 3px;
          width: 4rem;
          background: #ccad61;
          margin: 1rem auto;
        }

        p {
          font-size: 18px;
          margin: 1rem;
        }

        small {
          margin: 0 1rem;
          font-size: 16px;
          color: #013974;
          cursor: pointer;
          transition: all .2s ease;

          &:hover {
            letter-spacing: .1rem;
          }
        }

        a {
          position: absolute;
          bottom: 2rem;
          right: 2rem;
          transition: all .2s ease;

          &:hover {
            scale: 1.2;
          }
        }
      }

      .back {
        overflow: hidden;
        z-index: -1;
        // display: block;
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        p {
          margin: 0;
          font-size: 16px !important;
          text-align: justify;
        }

        .backIcon {
          position: absolute;
          bottom: 2rem;
          right: 2rem;
          transition: all .2s ease;

          &:hover {
            scale: 1.2;
          }
        }

        .content {
          margin: 1rem;
          display: flex;
          flex-direction: column;
          gap: .5rem;
        }

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: -1;
          background-size: 100% 100%;
          -webkit-transform: rotateY(180deg);
          -moz-transform: rotateY(180deg);
          -ms-transform: rotateY(180deg);
          -o-transform: rotateY(180deg);
          transform: rotateY(180deg);
        }
      }

      .front,
      .back {
        transition: all .2s linear;
      }

      .front {
        z-index: 1;
      }

      &.flipped {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
      }
    }
  }
}