html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Futura", "Trebuchet MS", Arial, sans-serif;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

::selection {
  background: #ccad61;
  color: #fff;
}

p {
  font-family: "Raleway", "Trebuchet MS", Arial, sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #eee;
}

::-webkit-scrollbar-thumb {
  background: #ccad61;
}

::-webkit-scrollbar-thumb:hover {
  background: #958049;
}

@font-face {
  font-family: "Futura";
  src: url('./assets/fonts/FuturaLT.woff2') format('woff2'),
       url('./assets/fonts/FuturaLT.woff') format('woff');
}

@font-face {
  font-family: "futura-bold";
  src: url('./assets/fonts/FuturaLT-Bold.woff2') format('woff2'),
       url('./assets/fonts/FuturaLT-Bold.woff') format('woff');
}

@font-face {
  font-family: "futura-light";
  src: url('./assets/fonts/FuturaLT-Light.woff2') format('woff2'),
       url('./assets/fonts/FuturaLT-Light.woff') format('woff');
}

@font-face {
  font-family: "futura-heavy";
  src: url('./assets/fonts/FuturaLT-Heavy.woff2') format('woff2'),
       url('./assets/fonts/FuturaLT-Heavy.woff') format('woff');
}

@font-face {
  font-family: "Raleway";
  src: url('./assets/fonts/Raleway-Regular.ttf');
}

@font-face {
  font-family: "raleway-semibold";
  src: url('./assets/fonts/Raleway-SemiBold.ttf');
}

@font-face {
  font-family: "raleway-black";
  src: url('./assets/fonts/Raleway-Black.ttf');
}

@font-face {
  font-family: "raleway-light";
  src: url('./assets/fonts/Raleway-Light.ttf');
}