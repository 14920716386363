nav .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82px;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 15px 1px rgb(0 0 0 / 7%);
  // backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 1);
  transition: all 0.2s;
  z-index: 100;

  .wrapper {
    max-width: 1500px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .logo img {
      height: 28.14px;
      width: 236px;
    }

    .links {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 450px;
      width: 100%;

      .link {
        display: flex;
        flex-direction: column;
        color: #011642;
        font-size: 15px;
        text-decoration: none;
        font-weight: 400;
        transition: all 0.2s;
        
        span:nth-child(2) {
          font-family: "futura-bold";
          font-weight: 900;
          font-size: 15px;
        }

        span.line {
          width: 45%;
          height: 1.5px;
          margin-top: 5px;
          background-color: #011642;
          transition: width 0.3s;
        }

        &:hover {
          color: #ccad61;

          span.line {
            width: 100%;
            background: #ccad61;
          }
        }

        &.active {
          color: #ccad61;

          span.line {
            background: #ccad61;
            width: 100%;
          }
        }
      }
    }

    .menuIcon {
      display: none;
      flex-direction: column;
      gap: 8px;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        gap: 5px;

        span {
          background: #ccad61;
        }
      }

      span {
        width: 28px;
        height: 2px;
        background-color: #011642;
        transition: all 0.3s;
      }
    }

  }

  &:not(.scrolled) {
    background-color: transparent;
    box-shadow: none;

    .link {
      color: rgba(255, 255, 255, .8) !important;
    }

    span.line {
      background-color: rgba(255, 255, 255, .8) !important;
    }

    .link:hover {
      color: #ccad61 !important;

      span.line {
        width: 100%;
        background: #ccad61 !important;
      }
    }

    .link.active {
      color: #ccad61 !important;

      span.line {
        background: #ccad61 !important;
        width: 100%;
      }
    }
  }
}

nav .menu {
  position: fixed;
  width: calc(300px - 3rem);
  display: none;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateX(100%);
  bottom: 0px;
  box-shadow: 0 0 15px 1px rgb(0 0 0 / 7%);
  background: #fff;
  padding: 6rem 2rem;
  transition: all 0.3s ease-in-out;
  z-index: 100;

  &.opened {
    transform: translateX(0);
  }

  .closeIcon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover span::before,
    &:hover span::after {
      background: #ccad61;
    }

    span {

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 28px;
        height: 2px;
        background: #011642;
        transition: all 0.3s;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  .menuLinks {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .link {
      display: flex;
      flex-direction: column;
      color: #011642;
      font-size: 19px;
      text-decoration: none;
      font-weight: 400;
      transition: all 0.2s;
      width: 100%;
      padding: 1rem 0;
      border-bottom: 1px solid #011642;

      span:last-child {
        font-family: "futura-bold";
        font-weight: 900;
        font-size: 20px;
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        color: #ccad61;
      }

      &.active {
        color: #ccad61;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  nav .margin {
    height: 60px;
  }

  nav .container {
    height: 60px;
    padding: 0 2rem;

    .wrapper {
      .links {
        display: none;
      }

      .menuIcon {
        display: flex;
      }
    }

    &:not(.scrolled) {
      background-color: #fff;
      box-shadow: 0 0 15px 1px rgb(0 0 0 / 7%);
    }
  }

  nav .menu {
    display: flex;
  }
}