.insights__heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem auto;
  max-width: 1200px;
  width: calc(100% - 2rem);
  justify-content: space-evenly;
  align-items: center;
  text-align: justify;
  font-size: 18px;
}

.insights__container {
  display: flex;
  flex-direction: column;
  margin: 4rem auto;
  max-width: 1200px;
  width: calc(100% - 2rem);
  gap: 2rem;


  &:nth-child(even) {
    .insight {
      align-items: flex-end;

      a.button {
        align-items: flex-end;
      }
    }
  }

  @media (min-width: 768px) {
    .insight {
      align-items: flex-start !important;

      a.button {
        align-items: flex-start !important;
      }
    }
  }

  .insight {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h3 {
      margin: 0;
      font-size: 26px;
      color: #013974;
      font-family: 'futura-bold';
      font-weight: 600;
      margin-bottom: 1rem;
    }

    span.line {
      width: 22rem;
      height: 4px;
      background-color: #ccad61;
    }

    div {
      max-width: 200px;
    }

    a.button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-decoration: none;
      color: #013974;
      font-size: 19px;
      font-weight: 700;
      letter-spacing: .4rem;
      transition: all 0.2s;
      margin-top: 1rem;

      .line {
        height: 2px;
        width: 30%;
        background: #013974;
        margin: 1rem 0 .5rem;
        transition: all 0.2s;
      }

      &:hover {
        letter-spacing: .5rem;

        .line {
          width: 100%;
        }
      }
    }
  }
}

.insight__container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 4rem auto;
  width: calc(100% - 2rem);
  max-width: 1200px;
  text-align: justify;

  .img-mobile {
    display: none;

    @media (max-width: 768px) {
      display: block;

      img {
        border-radius: 4px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }

  h3.title {
    font-size: 26px;
    text-transform: uppercase;
    font-family: "futura-bold";
    color: #013974
  }

  span.line {
    width: 30%;
    margin-top: 1rem;
    height: 3px;
    background-color: #ccad61;
  }

  small.byLine {
    font-size: 14px;
    font-family: "futura-light";
    font-style: italic;
    font-weight: 600;
    color: #013974;
  }

  h5.lead {
    font-size: 18px;
    font-family: "Futura";
    font-weight: 600;
    font-style: italic;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .flex {
    display: flex;
    gap: 2rem;

    @media (max-width: 768px) {
      flex-direction: column;

      .content {
        width: 100% !important;
      }

      .img {
        display: none;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      text-align: justify;
      width: 60%;
      justify-content: space-around;
    }

    .img {
      width: 40%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }

  p.closingLine {
    font-style: italic;
    margin-top: 1rem;
  }
}