.hero {
  height: calc(500px - 4rem);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 2rem;
  position: relative;
  overflow: hidden;

  .video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
  }

  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 100%);
  }

  .content {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 48px;
      color: #fff;
      margin-bottom: 0;
      font-family: "futura-bold";

      span {
        color: #ccad61;
      }
    }

    p {
      font-weight: 500;
      font-size: 20px;
      color: #fff;

      span {
        color: #ccad61;
        font-weight: 600;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .hero {
    h1 {
      font-size: 36px !important;
    }
    p {
      font-size: 18px !important;
    }
  }
}