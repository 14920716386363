.home__about {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin: 4rem auto;
  max-width: 1200px;
  width: calc(100% - 2rem);
  justify-content: space-evenly;
  min-height: calc(100vh - 500px - 10rem);

  .video {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .img img {
      width: 275px * 2;
      height: 225px * 2;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        transform: scale(1.02);
      }
    }
  }

  .content {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    h3 {
      font-size: 26px;
      line-height: 36px;
      color: #013974;
      font-weight: 1100;
      margin-bottom: 0;
    }

    .line {
      height: 3px;
      width: 30%;
      background: #ccad61;
      margin: 1.5rem 0 1rem;
    }

    p {
      font-size: 18px;
      text-align: justify;
      font-weight: 300;
      line-height: 22px;
    }
  }
}

.home__team {
  margin: 4rem auto;
  width: 100%;
  background-color: #ccad61;
  padding: 4rem 0;

  .container {
    margin: 0 auto;
    width: calc(100% - 2rem);
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  h3 {
    font-size: 26px;
    line-height: 36px;
    color: #013974;
    font-weight: 1100;
    margin-bottom: 0;
  }

  .container>.line {
    height: 3px;
    width: 12rem;
    background: #fff;
    margin: 1rem 0 .5rem;
  }

  p {
    font-size: 18px;
    text-align: justify;
    font-weight: 300;
    line-height: 22px;
  }

  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    color: #fff;
    font-size: 19px;
    font-weight: 700;
    letter-spacing: .4rem;
    transition: all 0.2s;
    margin-top: 1rem;

    .line {
      height: 2px;
      width: 30%;
      background: #fff;
      margin: 1rem 0 .5rem;
      transition: all 0.2s;
    }

    &:hover {
      letter-spacing: .5rem;

      .line {
        width: 100%;
      }
    }
  }
}

.home__doYouWant {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-evenly;
  margin: 4rem auto;
  max-width: 1200px;
  width: calc(100% - 2rem);

  .side {
    width: 50%;

    &:nth-child(2) {
      max-width: 350px;
    }

    height: 350px;
    display: flex;
    gap: 3.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
      border-radius: 4px;
    }
  }

  .divider {
    height: 350px;
    width: 2px;
    background: #ccad61;
  }

  h3 {
    font-size: 26px;
    text-align: center;
    line-height: 36px;
    color: #013974;
    font-weight: 1100;
    margin-bottom: 0;
  }

  .button {
    text-decoration: none;
    color: #013974;
    font-size: 16px;
    letter-spacing: .1rem;
    transition: all 0.2s;
    border: 1px solid #013974;
    padding: 1rem;

    .line {
      height: 2px;
      width: 30%;
      background: #013974;
      margin: 1rem 0 .5rem;
      transition: all 0.2s;
    }

    &:hover {
      background: #013974;
      color: #fff;
    }
  }
}

@media screen and (max-width: 1100px) {
  .home__hero {
    h1 {
      font-size: 36px !important;
    }

    p {
      font-size: 18px !important;
    }
  }

  .home__about {
    flex-direction: column;

    .video,
    .content {
      width: 100%;
    }
  }

  .home__doYouWant {
    flex-direction: column;

    .side {
      width: 100%;
      height: 300px;
    }

    .divider {
      width: 100%;
      height: 2px;
    }
  }
}

.home__learn-more {
  max-width: 1200px;
  width: calc(100% - 2rem);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  height: 6rem;
  align-items: center;
  transition: all 0.2s;
  opacity: 1;

  a {
    display: flex;
    align-items: center;
    gap: .5rem;
    justify-content: center;
    text-decoration: none;
    color: #ccad61;
    transition: all 0.2s;
    letter-spacing: .2rem;
    flex-direction: row-reverse;

    img {
      width: 1.5rem;
      height: 1.5rem;
      transition: all 0.2s;
      
    }

    &:hover {
      letter-spacing: .3rem;

      img {
        transform: rotate(90deg);
      }
    }
  }
}

.separator {
  max-width: 1200px;
  width: calc(100% - 2rem);
  margin: 0 auto;
  display: flex;
  justify-content: center;

  span {
    height: 4px;
    background: #ccad61;
    width: 55%;
  }
}

.work__heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem auto;
  max-width: 1200px;
  width: calc(100% - 2rem);
  justify-content: space-evenly;
  align-items: center;
  gap: .5rem;

  h1 {
    font-size: 48px;
    color: #013974;
    margin-bottom: 0;
    font-family: "futura-bold";

    span {
      color: #ccad61;
    }
  }

  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
  }
}

.work__centers {
  display: flex;
  flex-direction: column;
  gap: 8rem;
  max-width: 1200px;
  width: calc(100% - 2rem);
  margin: 4rem auto 10rem;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      font-size: 26px;
      text-align: justify;
      color: #013974;
    }
  }

  .centers {
    display: flex;
    flex-direction: column;
    gap: 6rem;

    .center {
      display: flex;
      gap: 2rem;
      align-items: center;
      position: relative;

      .img {
        width: 40%;
        border-radius: 4px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .line {
        height: 12rem;
        width: 2px;
        background: #ccad61;

        @media screen and (max-width: 900px) {
          display: none;
        }
      }

      .text {
        width: 55%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        cursor: pointer;
        transition: all .2s ease;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

        h4 {
          font-size: 24px;
          color: #013974;
          font-family: 'raleway-semibold';
          letter-spacing: .4rem;
          transition: all .3s ease-in-out;
          font-style: italic;
        }

        .container {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          animation: slideIn .4s ease-in-out;

          .content {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            h5 {
              font-size: 18px;
              color: #013974;
              font-family: 'raleway-semibold';
            }

            ul {
              font-size: 16px;
              font-family: 'raleway';
              list-style-type: none;
              display: flex;
              flex-direction: column;
              gap: .2rem;
              padding: 0 0 0 1rem;

              li {
                display: flex;
                gap: .5rem;
                align-items: center;

                span {
                  width: 1.2rem;
                  height: 2px;
                  background: #ccad61;
                }
              }
            }
          }
        }
      }

      &:nth-child(odd) {
        flex-direction: row-reverse;
      }

      @media screen and (max-width: 900px) {
        flex-direction: column;

        .text,
        .img {
          width: 100%;
        }

        .text h4 {
          text-align: center;
        }

        &:nth-child(odd) {
          flex-direction: column;
        }
      }
    }
  }
}

body:has(.container.scrolled) {
  .home__learn-more {
    opacity: 0;
  }
}