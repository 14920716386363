.career__heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem auto;
  max-width: 1200px;
  width: calc(100% - 2rem);
  justify-content: space-evenly;
  align-items: flex-start;

  h3 {
    font-size: 26px;
    line-height: 36px;
    color: #013974;
    font-weight: 1100;
    margin-bottom: 0;
  }

  .line {
    height: 3px;
    width: 8rem;
    background: #ccad61;
    margin: 1rem 0 .5rem;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
  }
}

.career__features {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin: 4rem auto;
  max-width: 1200px;
  width: calc(100% - 2rem);
  
  @media screen and (max-width: 768px) {
    gap: 4rem;
  }

  .career__feature {
    display: flex;
    align-items: center;
    gap: 2rem;

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      align-items: flex-start;
      width: 50%;
      text-align: left;

      h3 {
        font-size: 26px;
        line-height: 36px;
        color: #013974;
        font-weight: 1100;
        margin-bottom: 0;
      }

      .line {
        height: 3px;
        width: 7rem;
        background: #ccad61;
        margin: 1rem 0 .5rem;
      }

      p {
        font-size: 18px;
        font-weight: 300;
        line-height: 22px;
      }
    }

    &:nth-child(even) {
      flex-direction: row-reverse;
      
      .text {
        text-align: right;
        align-items: flex-end;
      }
    }

    .img {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column !important;
      align-items: center;

      .text {
        width: 100%;

        h3 {
          text-align: center;
        }

        p {
          text-align: justify !important;
        }

        align-items: center !important;

        span.line {
          width: 6rem;
        }
      }

      .img {
        width: 100%;
      }
    }

  }
}

.career__banner {
  width: 100%;
  display: flex;
  padding: 6rem 0;
  margin: 4rem auto;
  align-items: center;
  justify-content: center;
  background: #ccad61;

  div {
    max-width: 1200px;
    width: calc(100% - 2rem);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    h2 {
      font-size: 32px;
      color: #013974;
    }

    a {
      color: #013974;
      padding: 1rem 2rem;
      border-radius: 4px;
      font-size: 18px;
      font-weight: 500;
      text-decoration: none;
      border: 1px solid #013974;
      transition: all .2s ease-in-out;
      text-transform: uppercase;
      font-family: 'Raleway';
      letter-spacing: .2rem;
      min-width: 13rem;
      text-align: center;

      &:hover {
        background: #013974;
        color: #fff;
      }
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;
      gap: 4rem;
      align-items: center;
      text-align: center;

      h2 {
        font-size: 26px;
      }
    }
  }
}

.career__lookingFor {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem auto;
  max-width: 1200px;
  width: calc(100% - 2rem);
  justify-content: space-evenly;
  align-items: flex-start;

  h3 {
    font-size: 26px;
    line-height: 36px;
    color: #013974;
    font-weight: 1100;
    margin-bottom: 0;
  }

  .line {
    height: 3px;
    width: 8rem;
    background: #ccad61;
    margin: 1rem 0 .5rem;
  }

  div {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
  }
}